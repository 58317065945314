import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import MainNav from "../components/MainNav"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Joshua Maddox: Marketing,Code, Design, and Social Entrepreneurship" />
      <MainNav />
      <Hero />
    </Layout>
  )
}

export default IndexPage
